import { createStore } from 'vuex';
import { Produto, State, Usuario, Categoria } from "@/settings/interfaces";
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence<State>({
    key: 'compra',
    reducer: (state) => ({ compra: state.compra }),
    saveState: (key, state) => {
        localStorage.setItem(key, JSON.stringify(state));
    },
    restoreState: (key) => {
        const encoded = localStorage.getItem(key);
        if (encoded) {
            return JSON.parse(encoded);
        }
        return {
            fornecedor: {
                id: 0,
                nome: '',
                celular: '',
                nota: 0,
                nota_atual: 0,
            },
            produtos: new Array<Produto>(),
            atual: 0,
            valor: 0,
            porcentagem: 0,
            forma_pagamento: '',
        };
    }
})

const usuario = localStorage.getItem('usuario')
const usuarioJson: Usuario = usuario ? JSON.parse(usuario) : { id: 0 }

export default createStore({
    state: {
        usuario: usuarioJson,
        compra: {
            fornecedor: {
                id: 0,
                nome: '',
                celular: '',
                email: '',
                nota: 0,
                nota_atual: 0,
                cpf: '',
            },
            produtos: new Array<Produto>(),
            atual: 0,
            valor: 0,
            estrelas: 0,
            porcentagem: 0,
            forma_pagamento: '',
        },
        venda: new Array<{
            id: number,
            nome: string,
            preco_custo: number,
            preco_venda: number,
        }>(),
        carregando: false,
    },
    getters: {
        nomeFornecedor: state => state.compra.fornecedor.nome,
        fornecedor: state => state.compra.fornecedor,
        quantidadeCategorias: (state): number => {
            if (state.compra.produtos[state.compra.atual]) {
                return state.compra.produtos[state.compra.atual].categorias.length
            } else {
                return 0
            }
        },
        quantidadeProdutos: (state): number => {
            return state.compra.produtos.length
        },
        ultimaCategoria: state => {
            if (state.compra.produtos[state.compra.atual]) {
                return state.compra.produtos[state.compra.atual].categorias[state.compra.produtos[state.compra.atual].categorias.length - 1]
            } else {
                return null
            }
        },
        primeiraCategoria: state => {
            if (state.compra.produtos[state.compra.atual]) {
                return state.compra.produtos[state.compra.atual].categorias[0]
            } else {
                return null
            }
        },
        categoriasAtual: state => {
            if (state.compra.produtos[state.compra.atual]) {
                return state.compra.produtos[state.compra.atual].categorias
            } else {
                return []
            }
        },
        produtos: state => state.compra.produtos,
        produtoAtual: (state): Produto => {
            return state.compra.produtos[state.compra.atual]
        },
        carregando: state => state.carregando,
        venda: state => state.venda,
    },
    mutations: {
        removeUsuario(state) {
            state.usuario = {
                id: 0,
                nome: '',
                email: '',
                tipo: '',
                ativo: false,
            }
            localStorage.removeItem('usuario')
        },
        setVenda(state, venda) {
            state.venda = venda
        },
        setUsuario(state, usuario) {
            state.usuario = usuario
            localStorage.setItem('usuario', JSON.stringify(usuario))
        },
        setFornecedor(state, fornecedor) {
            state.compra.fornecedor = fornecedor;
            state.compra.fornecedor.nota_atual = fornecedor.nota;
        },
        clearCompra(state) {
            state.compra = {
                fornecedor: {
                    id: 0,
                    nome: '',
                    celular: '',
                    email: '',
                    nota: 0,
                    nota_atual: 0,
                    cpf: '',
                },
                produtos: Array<Produto>(),
                atual: 0,
                valor: 0,
                estrelas: 0,
                porcentagem: 0,
                forma_pagamento: '',
            }
        },
        addCategoria(state, categoria: Categoria) {
            const index = state.compra.atual;
            state.compra.produtos[index].categorias.push(categoria);
        },
        removerUltimaCategoria(state) {
            const index = state.compra.atual;
            state.compra.produtos[index].categorias.pop();
        },
        addProduto(state) {
            state.compra.produtos.unshift({
                categorias: Array<Categoria>(),
                detalhes: {
                    estrelas: 0,
                    nome: '',
                    marca_id: 0,
                    observacao: '',
                    marca: '',
                    preco_custo: 0,
                    preco_venda: 0,
                    porcentagem: 0,
                    preco_maior: {
                        preco: 0,
                    },
                    preco_menor: {
                        preco: 0,
                    },
                    preco_padrao: {
                        preco: 0,
                    },
                    letra: '',
                    numero: '',
                    tamanho: '',
                    cor: '',
                    tecido: '',
                    estado: '',
                    valores: Array<number>(),
                    estampa: '',
                },
                opened: false,
            });
        },
        changeAtual(state, atual: number) {
            state.compra.atual = atual;
        },
        removeProduto(state, index: number) {
            state.compra.produtos.splice(index, 1);
        },
        removerAtual(state) {
            state.compra.produtos.splice(state.compra.atual, 1);
        },
        setValor(state, valor: number) {
            state.compra.valor = valor;
        },
        setPorcentagem(state, porcentagem: number) {
            state.compra.porcentagem = porcentagem;
        },
        setCarregando(state, carregando: boolean) {
            state.carregando = carregando;
        },
        setEstrelas(state, estrelas: number) {
            state.compra.estrelas = estrelas;
        },
        setFormaPagamento(state, forma_pagamento: string) {
            state.compra.forma_pagamento = forma_pagamento;
        },
        updateProduto(state, { index, produtoDetalhes, categories }) {
            state.compra.produtos[index] = {
                categorias: categories ?? state.compra.produtos[index].categorias,
                detalhes: {
                    ...produtoDetalhes,
                },
                opened: state.compra.produtos[index].opened,
            };
        },
        setCompra(state, compra = state.compra) {
            state.compra = compra
        }
    },
    actions: {
        novoProduto({ commit }) {
            this.state.compra.atual = 0;
            commit('addProduto');
        },
        logout({ commit }) {
            // console.log(this.state.compra);
            commit('removeUsuario');
            commit('clearCompra');
        }
    },
    modules: {
    },
    plugins: [vuexLocal.plugin],
})
